var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-snackbar',{attrs:{"color":_vm.color,"timeout":3000,"top":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-icon',{attrs:{"small":"","color":"white"},on:{"click":function($event){_vm.alert = false}}},[_vm._v("mdi-close")])],1),_c('v-row',{staticClass:"auth-form-cont",staticStyle:{"min-height":"80vh"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"9","md":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline secondary white--text font-weight-regular",attrs:{"primary-title":""}},[_vm._v(" Create an account ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Full Name *","outlined":"","prepend-inner-icon":"mdi-account","rules":[
                (v) =>
                  (!!v && v.length > 3) || 'Item is required at least 3 characters',
                (v) =>
                  /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) ||
                  'Name Must be letters only with no spaces',
              ],"color":"blue","id":"user-fullName"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"Email *","type":"email","prepend-inner-icon":"mdi-email","rules":[
                (v) => !!v || 'Email is required',
                (v) =>
                  /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) ||
                  'E-mail must be valid',
              ],"color":"blue","outlined":"","id":"user-email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('phoneInput',{on:{"update":_vm.assignPhone}}),_c('v-text-field',{staticClass:"my-7",attrs:{"label":"Referral Code","outlined":"","prepend-inner-icon":"mdi-offer","color":"blue","id":"user-fullName","hide-details":""},model:{value:(_vm.referral_code),callback:function ($$v) {_vm.referral_code=$$v},expression:"referral_code"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"label":"Password","type":_vm.show ? 'text' : 'password',"prepend-inner-icon":"mdi-lock","rules":[
                (v) => !!v || 'Password is required',
                (v) => v.length >= 8 || 'Password must be at least 8 characters',
              ],"color":"blue","outlined":"","id":"user-password"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"label":"Confirm Password","type":_vm.show1 ? 'text' : 'password',"prepend-inner-icon":"mdi-lock-check","rules":[
                (v) => !!v || 'Password confirmation is required',
                (v) => v === _vm.password || 'This doesnot match the passsword.',
              ],"color":"blue","outlined":"","id":"user-confirm-password"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-checkbox',{attrs:{"rules":[(v) => !!v || 'You must agree to continue!'],"required":"","color":"secondary","id":"terms-agreement-checkbox"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree on our site "),_c('v-btn',{attrs:{"text":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.goTo('terms')}}},[_vm._v(" terms & conditions ")]),_c('span',[_vm._v("&")]),_c('v-btn',{attrs:{"text":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.goTo('privacy')}}},[_vm._v(" Privacy Policy ")])],1)]},proxy:true}]),model:{value:(_vm.agreed),callback:function ($$v) {_vm.agreed=$$v},expression:"agreed"}})],1),_c('v-card-actions',[_c('router-link',{staticClass:"blue--text",attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Login instead")])]),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","tile":"","color":"secondary","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("Submit")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }