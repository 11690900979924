<template>
  <v-container>
    <v-snackbar :color="color" :timeout="3000" top v-model="alert">
      {{ text }}
      <v-icon small color="white" @click="alert = false">mdi-close</v-icon>
    </v-snackbar>
    <v-row
      style="min-height: 80vh"
      class="auth-form-cont"
      justify="center"
      align="center"
    >
      <v-col cols="12" sm="9" md="6">
        <v-card>
          <v-card-title
            class="headline secondary white--text font-weight-regular"
            primary-title
          >
            Create an account
          </v-card-title>
          <v-form ref="form" v-model="valid" v-on:submit.prevent>
            <v-card-text>
              <v-text-field
                label="Full Name *"
                v-model="name"
                outlined
                prepend-inner-icon="mdi-account"
                :rules="[
                  (v) =>
                    (!!v && v.length > 3) || 'Item is required at least 3 characters',
                  (v) =>
                    /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) ||
                    'Name Must be letters only with no spaces',
                ]"
                color="blue"
                id="user-fullName"
              >
              </v-text-field>

              <v-text-field
                label="Email *"
                v-model="email"
                type="email"
                prepend-inner-icon="mdi-email"
                :rules="[
                  (v) => !!v || 'Email is required',
                  (v) =>
                    /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) ||
                    'E-mail must be valid',
                ]"
                color="blue"
                outlined
                id="user-email"
              >
              </v-text-field>

              <phoneInput @update="assignPhone" />

              <v-text-field
                label="Referral Code"
                v-model="referral_code"
                outlined
                prepend-inner-icon="mdi-offer"
                color="blue"
                id="user-fullName"
                hide-details
                class="my-7"
              >
              </v-text-field>

              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                v-model="password"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                prepend-inner-icon="mdi-lock"
                :rules="[
                  (v) => !!v || 'Password is required',
                  (v) => v.length >= 8 || 'Password must be at least 8 characters',
                ]"
                color="blue"
                outlined
                id="user-password"
              >
              </v-text-field>

              <v-text-field
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                label="Confirm Password"
                v-model="confirmPassword"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                prepend-inner-icon="mdi-lock-check"
                :rules="[
                  (v) => !!v || 'Password confirmation is required',
                  (v) => v === password || 'This doesnot match the passsword.',
                ]"
                color="blue"
                outlined
                id="user-confirm-password"
              >
              </v-text-field>
              <v-checkbox
                v-model="agreed"
                :rules="[(v) => !!v || 'You must agree to continue!']"
                required
                color="secondary"
                id="terms-agreement-checkbox"
              >
                <template v-slot:label>
                  <div>
                    I agree on our site
                    <v-btn text x-small color="blue" @click="goTo('terms')">
                      terms & conditions
                    </v-btn>
                    <span>&</span>
                    <v-btn text x-small color="blue" @click="goTo('privacy')">
                      Privacy Policy
                    </v-btn>
                  </div>
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions>
              <router-link :to="{ name: 'login' }" class="blue--text"
                ><span> Login instead</span></router-link
              >
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                tile
                color="secondary"
                @click="submit"
                :disabled="!valid"
                :loading="loading"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { register, headersNoAuth } from '../links'
import phoneInput from '../components/mobile.vue'

export default {
  title: '-Register',
  components: {
    phoneInput
  },
  data () {
    return {
      alert: false,
      color: '',
      text: '',
      show: false,
      show1: false,
      valid: true,
      loading: false,
      name: '',
      email: '',
      phone: '',
      referral_code: '',
      password: '',
      confirmPassword: '',
      agreed: false
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    goTo (page) {
      const routeData = this.$router.resolve({ name: page })
      window.open(routeData.href, '_blank')
    },
    submit () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone.valid) {
          const body = {
            name: this.name,
            email: this.email,
            password: this.password,
            phone: this.phone.nationalNumber,
            countryIsoCode: this.phone.countryCode,
            referral_code: this.referral_code
          }
          this.$http
            .post(register, body, { headers: headersNoAuth() })
            .then((response) => {
              if (response.body.status === false) {
                this.alert = true
                this.color = 'error'
                this.text = response.body.message
                this.loading = false
              } else {
                this.$router.push({ name: 'verify', params: { email: this.email } })
                this.loading = false
              }
            })
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please provide avalid mobile number'
          this.loading = false
        }
      }
    }
  },
  beforeCreate () {
    if (this.$cookies.isKey('userToken')) this.$router.push({ name: 'profile' })
  }
}
</script>

<style>
/* .auth-form-cont {
  min-height: 90vh;
} */
.remove-controls input[type="number"] {
  -moz-appearance: textfield;
}
.remove-controls input::-webkit-outer-spin-button,
.remove-controls input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
